<template>
  <div>
    <a-modal
      v-model="isShow"
      centered
      :width="800"
      :mask-closable="false"
      :ok-button-props="{ props: { loading: submitting } }"
      title="导出"
      @ok="handleSubmit"
    >
      <a-form
        class="simiot-nested-search-form"
        :form="form"
        @submit="handleSearch"
      >
        <a-row :gutter="24">
          <a-col :span="8">
            <a-form-item>
              <a-input
                v-decorator="['carrier_account_name', {
                  normalize: this.$lodash.trim
                }]"
                placeholder="请输入运营商账户名称"
                allow-clear
              />
            </a-form-item>
          </a-col>

          <a-col :span="8">
            <a-form-item>
              <a-space>
                <a-button type="primary" html-type="submit">
                  搜索
                </a-button>
                <a-button @click="handleReset">
                  刷新
                </a-button>
              </a-space>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>

      <a-table
        size="middle"
        :columns="columns"
        :data-source="data"
        :loading="loading"
        :pagination="false"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :scroll="{ y: 350 }"
        row-key="id"
      />
    </a-modal>
  </div>
</template>

<script>
import {
  findCancelCardManagesCarrierAccountsByExport,
  exportExpectCancelSimCards
} from '@/api/cancel_card_manage'
import { exportExcel } from '@/api/excel'
export default {
  name: 'ExportExpectCancelCardModal',
  props: {
    visible: {
      type: Boolean,
      required: true
    },
    carrierAccountIds: {
      type: Array,
      default() {
        return []
      }
    }
  },
  data() {
    return {
      form: this.$form.createForm(this, { name: 'export_expect_cancel_card_modal' }),
      submitting: false,
      data: [],
      loading: true,
      query: {},
      selectedRowKeys: []
    }
  },
  created() {
    this.fetchData()
  },
  computed: {
    isShow: {
      get() {
        return this.visible
      },
      set(val) {
        this.$emit('update:visible', val)
      }
    },
    columns() {
      return [
        {
          title: '运营商账户',
          dataIndex: 'carrier_account_name'
        }
      ]
    }
  },
  methods: {
    handleSearch(e) {
      e.preventDefault()
      this.submitSearch(this.form.getFieldsValue())
    },

    submitSearch(search) {
      // 赋值搜索条件，保留 per_page
      this.query = search
      this.fetchData()
    },

    handleReset() {
      this.form.resetFields()
      this.query = {}
      this.fetchData()
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys
    },

    fetchData() {
      this.loading = true
      this.selectedRowKeys = []
      findCancelCardManagesCarrierAccountsByExport(Object.assign({}, this.query)).then((res) => {
        if (res.code === 0) {
          this.data = res.data
          // 过滤一下 如果父组件传过来的id在这里不存在 就去掉
          for (let i = 0; i < this.data.length; i++) {
            if (this.carrierAccountIds.indexOf(this.data[i].id) >= 0) {
              this.selectedRowKeys.push(this.data[i].id)
            }
          }
        }
        this.loading = false
      })
    },

    handleSubmit(e) {
      e.preventDefault()
      if (this.submitting) {
        this.$warning({
          title: '请勿重复提交',
          content: ''
        })
        return
      }

      this.form.validateFields((err, values) => {
        if (!err) {
          this.submitting = true
          exportExpectCancelSimCards({ carrier_account_ids: this.selectedRowKeys }).then((res) => {
            if (res.code === 0 && res.data.is_direct_download === true) {
              exportExcel(res.data.id, res.data.file_name)
              this.isShow = false
            }
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
